import { computed, nextTick, onBeforeMount, onBeforeUnmount, ref } from 'vue'
import { useRouter } from 'vue-router/composables'
import emitter from '/~/core/emitter'
import { useAccess } from '/~/composables/access/use-access'
import { useQuickBuyCheckout } from '/~/composables/checkout'
import { FlowType } from '/~/composables/checkout/checkout-types'
import { usePaymentMethods } from '/~/composables/payment-methods'
import { useProvider } from '/~/composables/provider'

export function useQuickBuyPaymentMethodsComponent() {
  const { configLoading } = usePaymentMethods()
  const { payment } = useQuickBuyCheckout()
  const { splitPaymentOrderAllowed } = useProvider()
  const { withAccessCheck } = useAccess()
  const router = useRouter()

  const isShowAddButton = computed(
    () =>
      !splitPaymentOrderAllowed(FlowType.quickBuy) &&
      payment.canAddPaymentMethods
  )
  const addButtonDisabled = computed(() => payment.submitting)

  const loading = computed(() => configLoading.value)

  onBeforeMount(() => {
    emitter.on('checkout-method-selected', goToCheckout)
  })

  onBeforeUnmount(() => {
    emitter.off('checkout-method-selected', goToCheckout)
  })

  function goToCheckout() {
    nextTick(() => {
      router.push({ name: 'quick-buy-checkout' })
    })
  }

  function addPaymentMethod() {
    withAccessCheck({
      callback: () => {
        router.push({
          hash: '#profile-payment-methods-add',
          params: {
            source: payment.orderType,
          },
        })
      },
      config: {
        actionType: 'paymentMethods',
      },
    })
  }

  const backRoute = ref({
    name: 'quick-buy-checkout',
    query: router.currentRoute.query,
  })

  return {
    isShowAddButton,
    addButtonDisabled,
    addPaymentMethod,
    loading,
    backRoute,
  }
}
